import React from "react";
import {NosEquipesPage} from 'amg-it-theme'; 

let nosEquipesPageData ={
  header: {
    background: "societe",
    title: "nos équipes",
    path: "ACCUEIL / SOCIÉTÉ / NOS EQUIPES"
  },
  content:{
    category: "nos equipes",
    title: "Nos équipes de qualité",
    subtitle: "Le cœur de métier d’AMG-IT, c’est la gestion de l’humain. L’ESN emploie une cinquantaine de collaborateurs salariés, intérimaires et indépendants."
  },
  collaborateurs: [
    /*{
      photo: "",
      title: "John John",
      text: "Sells Stuff"
    },
    {
      photo: "",
      title: "Jane Jane",
      text: "Buys Stuff"
    }*/
  ]
}

const LocalPage = () => (
  <NosEquipesPage {...nosEquipesPageData}/>
);

export default LocalPage;